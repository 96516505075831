$(document).ready(function() {
	var lang = $('html').attr('lang');

	/**
	 * Gestion resize + scroll
	 */

	// Resize de la fenêtre
	resize_window();
	$(window).resize(function() {
		resize_window();
	});

	// Gestion du scroll
	$(window).scroll(function() {

	});

	// Shifter
	$.shifter({
		maxWidth: '1240px'
	});

	// WOW
	new WOW().init();

	// Columnizer
	if ($('.columnizer').length > 0) {
		$('.columnizer').columnize({
			column : 2
		});
	}

	// Rate it
	if ($('.rateit').length > 0) {
		$('.rateit').on('click', function() {
			var rateit = $(this);
			var rateit_users_id = rateit.data('usersid');
			var rateit_produits_id = rateit.data('produitsid');
			var rateit_note = rateit.rateit('value');

			$.ajax({
				type	: "POST",
				url		: lang_path+"/produits/rateit/index.html",
				data	: 'users_id=' + rateit_users_id + '&produits_id=' + rateit_produits_id + '&note=' + rateit_note,
				error	: function(msg) {
					alert(msg);
				},
				success	: function(data) {
					rateit.rateit('readonly', true);
				}
			});
		});
	}

   $(".dotdotdot").dotdotdot({
      // Options go here
   });

	$(".btn_savoir_plus").click(function(event) {
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
		}
		$(this).next().fadeToggle('slow', function() {
		});
		return false;
	});

	$(".btn_reservation").click(function(event) {
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
		}
		$(this).next().fadeToggle('slow', function() {
		});
		return false;
	});

	if( $(window).width()<768 ){
		$("#domaine-courant").click(function(event) {
			$("#nav-domaines").slideToggle("fast");
		});
	}

	//same height
	var height_col = 0;
	$(".sameheight").each(function(index, el) {
		height_current = $(".sameheight").height();

		if (height_current > height_col) {
			height_col = height_current;
		}
	});

	$(".sameheight").each(function(index, el) {
		$(".sameheight").height(height_current);
	});

	// Owl Carousel
	if ($('#owl-photos-produit').length > 0) {
		$('#owl-photos-produit').owlCarousel({
			nav: true,
			dots: true,
			margin: 20,
			loop: true,
			autoWidth: true,
			items: 2
		});
	}

	if ($('#owl-carousel-actualites').length > 0) {
		$('#owl-carousel-actualites').owlCarousel({
			nav: true,
			dots: true,
			loop: true,
			items: 1
		});
	}

	if ($('#slide_prod_associe').length > 0) {
		$('#slide_prod_associe').owlCarousel({
			items: 4,
			loop: true,
			margin: 10,
			nav: true,
			dots: true,
			animateIn: 'fadeIn',
			animateOut: 'fadeOut',
			responsive:{
				0:{
					items:1
				},
				600:{
					items:3
				},
				1000:{
					items:4
				}
			}
		});
	}

	/* MENU RECHERCHE */
	$("#loupe").click(function(event) {
		$("#form-search").slideToggle();
	});
});

function resize_window() {

}
