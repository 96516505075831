$(document).ready(function() {
	var lang = $('html').attr('lang');

	/**
	 * Gestion resize + scroll
	 */

	// Resize de la fenêtre
	fw_resize_window();
	$(window).resize(function() {
		fw_resize_window();
	});

	// Gestion du scroll
	$(window).scroll(function() {

	});

	$('.scrollto').on('click', function() {
		var href = $(this).attr('href');
		var hash = href.substring(href.indexOf('#'));
		var offset = $(this).data('offset') || 0;
		if ($(hash).length > 0) {
			$('html, body').animate({scrollTop: $(hash).offset().top + offset}, 2000, 'easeOutExpo');
			return false;
		}
	});

	$('.scrolltop').on('click', function(e) {
		$('html, body').animate({scrollTop: 0}, 2000, 'easeOutExpo');
		return false;
	});

	if (window.location.hash != '') {
		if (window.location.hash == '#_=_') {
			window.location.hash = '';
		} else {
			var target = window.location.hash;
			if ($(target).length > 0) {
				$('html, body').animate({scrollTop: $(target).offset().top}, 2000, 'easeOutExpo');
//				window.location.hash = '';
			}
		}
	}

	/**
	 * Actions reatives au Menu
	 */

	// Initialisation du menu
	if ($('body').hasClass('mode_ecran')) {
		// Menu écran
		$('#menu > li.submenu ul, #menu > li.bigmenu > ul').parent().each(function() {
			var menu = $('#menu');
			var li = $(this);
			var submenu = li.find('ul:eq(0)');
			li.hover(
				function() {
					//li.addClass('menuselected');
					var duration_in = menu.attr('data-duration-in') > 0 ? parseInt(menu.attr('data-duration-in')) : 0;
					var easing_in = menu.attr('data-easing-in') ? menu.attr('data-easing-in') : 'linear';
					submenu.stop().fadeIn(duration_in, easing_in);
				},
				function() {
					//li.removeClass('menuselected');
					var duration_out = menu.attr('data-duration-out') > 0 ? parseInt(menu.attr('data-duration-out')) : 0;
					var easing_out = menu.attr('data-easing-out') ? menu.attr('data-easing-out') : 'linear';
					submenu.stop().fadeOut(duration_out, easing_out);
				}
			);
		});
	} else {
		// Menu mobile / tablette
		$('#menu a').on('click', function(e) {
			var alink = $(this);
			var li = alink.parent();
			var submenu = li.find('ul:eq(0)');

			// Si je clique sur un lien qui a une sous arborescence :
			// - si c'est mon premier clic sur ce lien (classe clicked non présente) :
			if (submenu.length > 0 && !alink.hasClass('clicked')) {
				e.preventDefault(); // Je désactive le clic
				alink.addClass('clicked'); // J'ajoute la classe clicked
				li.addClass('menuselected');

				// Je masque les autres arborescences
				alink.parents('.niveau1').siblings().each(function() {
					$(this).removeClass('clicked menuselected');
					$(this).find('.clicked, .menuselected').removeClass('clicked menuselected');
					$(this).find('ul').hide();
				});

				// Je déroule la sous arborescence
				submenu.slideDown();
				if ($('body').hasClass('shifter')) {
					$('.barre_menu').animate({scrollTop: li.offset().top});
				} else {
					$('html,body').animate({scrollTop: li.offset().top});
				}
			}
		});
	}
	$('.deploiement_menu').on('click', function() {
		$('#barre_menu').slideToggle();
		return false;
	});

	/**
	 * Partage
	 */

	// Partage Facebook
	$('.share_facebook').on('click', function() {
		var url = $(this).data('url') ? $(this).data('url') : location.href;
		var title = $(this).data('title') ? $(this).data('title') : document.title;
		window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(url)+'&t='+encodeURIComponent(title),'','toolbar=0,status=0,width=626,height=436');
		return false;
	});

	// Partage Twitter
	$('.share_twitter').on('click', function() {
		var text = $(this).data('text') ? $(this).data('text') : document.title;
		window.open('http://twitter.com/share?text='+text,'','toolbar=0,status=0,width=626,height=436');
		return false;
	});

	// Partage Google +
	$('.share_google_plus').on('click', function() {
		var url = $(this).data('url') ? $(this).data('url') : location.href;
		window.open('https://plus.google.com/share?url='+encodeURIComponent(url),'','menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=600,height=600');
		return false;
	});

	// Gestion Placeholder (uniquement pour IE < 10)
	/*if ($.browser.msie && ($.browser.version == '7.0' || $.browser.version == '8.0' || $.browser.version == '9.0')) {
		$('[placeholder]').focus(function() {
			var input = $(this);
			if (input.val() == input.attr('placeholder')) {
				input.val('');
				input.removeClass('placeholder');
			}
		}).blur(function() {
			var input = $(this);
			if (input.val() == '' || input.val() == input.attr('placeholder')) {
				input.addClass('placeholder');
				input.val(input.attr('placeholder'));
			}
		}).blur();
		$('[placeholder]').parents('form').submit(function() {
			$(this).find('[placeholder]').each(function() {
				var input = $(this);
				if (input.val() == input.attr('placeholder')) {
					input.val('');
				}
			});
		});
	}*/

	if ($('#cookies').length > 0) {
		$.cookieCuttr({
			cookieDeclineButton					: false,
			cookieAnalyticsMessage				: $('#cookieAnalyticsMessage').val(),
			cookieWhatAreLinkText				: $('#cookieWhatAreLinkText').val(),
			cookieDeclineButtonText				: $('#cookieDeclineButtonText').val(),
			cookieAcceptButtonText				: $('#cookieAcceptButtonText').val(),
			cookieWhatAreTheyLink				: $('#cookieWhatAreTheyLink').val(),
			cookieNotificationLocationBottom	: true
		});
	}
});

function fw_resize_window() {
	$('.info_window').show();
	var window_size_class = '';
	$('.window_size').each(function() {
		if ($(this).is(':visible')) {
			window_size_class = $(this).attr('class').split(' ')[0];
		}
	});
	$('.info_window').not('.debug').hide();
	var window_width = $(window).width();
	var window_height = $(window).height();

	$('body').removeClass('mode_ecran mode_tablette mode_mobile').addClass('mode_' + window_size_class);
	$('.window_size .size').html(window_width + ' x ' + window_height);

	$('.centerVertical').each(function(index) {
		var content_height = $(this).height();
		var conteneur_height = $(this).parent().height();
		var padding_top = parseInt((conteneur_height - content_height) / 2);

		$(this).css({'padding-top' : padding_top + 'px'});
	});
}
