//=require _respond.min.js
//=require _jquery-1.11.0.min.js
//=require _jquery.easing.min.js
//=require _jquery.easing.compatibility.js
//=require _jquery.cookie.js
//=require _jquery.cookiecuttr.js
//=require _jquery.fs.shifter.min.js
//=require _wow.min.js
//=require _owl.carousel.min.js
//=require _jquery.dotdotdot.js
//=require _framework.js
//=require _general.js